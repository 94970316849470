<template>
  <el-main>
    <el-form
      :model="formData"
      :rules="rules"
      ref="changePassword"
      label-width="84px"
      class="demo-formData"
    >
      <el-form-item label="手机号" prop="phoneNumber" class="phoneNumber">
        <div class="flexJsbAc">
          <el-input
            type="text"
            v-model="formData.phoneNumber"
            clearable
          ></el-input>
          <el-button type="" size="mini" @click="getVerifyCode"
            >获取验证码</el-button
          >
          <!-- <el-button @click.prevent="removeDomain(domain)">删除</el-button> -->
        </div>
      </el-form-item>
      <el-form-item label="验证码" clearable prop="verifyCode">
        <el-input type="text" v-model="formData.verifyCode"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="newPassword">
        <el-input
          type="password"
          v-model="formData.newPassword"
          show-password
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="formData.checkPass"
          show-password
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')"
          >提交</el-button
        >
        <el-button @click="resetForm('formData')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
import { formatDate } from "@/common/date";
import { changePassword, getResetPasswordVerifyCode } from "@/api/login";

export default {
  name: "other",
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      //var passwordreg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/; //数字、字母、特殊字符
      var passwordreg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,20}$/; //大小写字母，数字，特殊字符
      if (value == null) {
        callback(new Error("请输入密码"));
      } else {
        if (!passwordreg.test(value)) {
          callback(
            new Error(
              "密码必须包含大写字母、小写字母、数字和特殊字符的其中三种，且长度为8到12位！"
            )
          );
        } else {
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formData.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    return {
      formData: {},
      rules: {
        newPassword: [
          // { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        checkPass: [
          // { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        verifyCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        phoneNumber: [
          // { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: isMobileNumber, trigger: "blur" },
        ],
      },
      userInfo: null,
      verifyCodeInfo: null,
    };
  },
  components: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  filters: {},
  activated() {},
  methods: {
    getVerifyCode() {
      if (!this.formData.phoneNumber) {
        this.$message({ type: "warning", message: "请输入正确的手机号!" });
        return false;
      }
      getResetPasswordVerifyCode(this.formData.phoneNumber)
        .then((response) => {
          if (response.success) {
            this.formData.verifyCodeId = response.data;
            this.$message({ type: "success", message: "验证码发送成功!" });
          } else {
          }
        })
        .catch(() => {});
    },
    submitForm() {
      this.$refs["changePassword"].validate((valid) => {
        if (valid) {
          let formData = { ...this.formData };
          changePassword(formData)
            .then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "修改成功!" });
                window.localStorage.clear();
                window.location.href = window.location.origin + "/login";
              } else {
              }
            })
            .catch(() => {});
        } else {
          console.log("error submit!!");
        }
      });
    },
    resetForm() {
      this.$refs["changePassword"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  padding: 8px !important;
  //   height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  .demo-formData {
    // margin-top: 2rem;
    .el-form-item {
      margin-bottom: 35px !important;
      text-align: left;
    }
    .phoneNumber {
      .el-input {
        width: 55%;
      }
    }
    .verifyCode {
    }
  }
}
</style>
