<template>
  <div class="login">
    <div class="login-mask" />
    <div class="login-form-wrap">
      <div class="mx-6 login-form">
        <!-- 登录 -->
        <div v-if="!changePassword" class="px-2 py-10 login-form__content">
          <header>
            <img
              src="https://oss.eggxiaoer.com/web/hermes/logo.png"
              class="mr-4"
            />
            <div class="title">{{ title }}</div>
          </header>

          <el-form
            class="mx-auto mt-10"
            :model="params"
            :rules="rules"
            ref="loginForm"
            @keyup.enter.native="submitForm"
          >
            <el-form-item  prop="username">
              <el-input
                size="large"
                v-model="params.username"
                placeholder="账号"
                clearable
              />
            </el-form-item>
            <el-form-item  prop="password">
              <el-input
                type="password"
                size="large"
                visibilityToggle
                v-model="params.password"
                placeholder="密码"
                clearable
              />
            </el-form-item>
            <el-row type="flex" class="el-row" justify="end">
              <el-col :span="6">
                <el-link :underline="false" @click="forgetPassword"
                  >重置密码?</el-link
                >
              </el-col>
            </el-row>
            <!-- <el-form-item ref="slideVerifyContainer">
              <slide-verify
                :w="slideVerifyContainerWidth"
                :h="145"
                ref="slideblock"
                @again="onAgain"
                @fulfilled="onFulfilled"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                :slider-text="text"
                :accuracy="accuracy"
              ></slide-verify>
            </el-form-item> -->

            <el-form-item>
              <el-button
                type="primary"
                size="medium"
                class="rounded-sm"
                :loading="params.loading"
                @click="submitForm"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- changePassword -->
        <div class="changePassword" v-if="changePassword">
          <div class="close flexFJA">
            <i @click="closeChangePassword" class="el-icon-close"></i>
            <div class="title">重置密码</div>
          </div>

          <changePassword></changePassword>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getUserInfo, getPermissionInfo } from "@/api/user";
import { login } from "@/api/login";
import { TOKEN_KEY, REAL_NAME_KEY, ROLE_USERID_KEY } from "@/common/constants";
// import SlideVerify from "vue-monoplasty-slide-verify";
// Vue.use(SlideVerify);
import changePassword from "@/components/login/changePassword";

export default {
  name: "login",
  created() {
    var tokenId = window.localStorage.getItem(TOKEN_KEY);

    if (tokenId != null && tokenId.length > 0) {
      this.hasToken = true;
      this.$router.push("/");
    } else {
      this.hasToken = false;
    }
  },
  data() {
    var checkAccount = (rule, value, callback) => {
      if (!value) {
         callback(new Error("账号不能为空"));
      }
      callback();
    };
    // var validatePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入密码"));
    //   } else {
    //     callback();
    //   }
    // };
    var validatePass = (rule, value, callback) => {
      //var passwordreg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/; //数字、字母、特殊字符
      var passwordreg =
        /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,20}$/; //大小写字母，数字，特殊字符
      if (!value) {
        callback(new Error("请输入密码"));
      } else {
        if (!passwordreg.test(value)) {
          callback(
            new Error(
              "密码必须包含大写字母、小写字母、数字和特殊字符的其中三种，且长度为8到12位！"
            )
          );
        } else {
          callback();
        }
      }
    };

    return {
      title: "盛银内部管理系统",
      hasToken: false,
      params: {
        password: "",
        username: "",
        loading: false,
      },
      loading: false,
      rules: {
        username: [{ validator: checkAccount, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        // verify: unref(openLoginVerifyRef) ? [{ required: true, message: '请通过验证码校验' }] : [],
      },
      //slide-verify
      msg: "",
      text: "向右滑动->",
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 6,
      verify: false,
      // slideVerifyContainerWidth: null,
      changePassword: false,
    };
  },
  components: {
    changePassword,
  },
  mounted() {
    // console.log(this.$refs.slideVerifyContainer.$el.clientWidth);
    // this.slideVerifyContainerWidth = this.$refs.slideVerifyContainer.$el.clientWidth;
  },
  methods: {
    forgetPassword() {
      console.log("重置密码");
      this.changePassword = true;
    },
    closeChangePassword() {
      this.changePassword = false;
    },
    //slide-verify
    onSuccess() {
      console.log("验证通过");
      this.msg = "login success";
      this.verify = true;
    },
    onFail() {
      console.log("验证不通过");
      this.msg = "";
    },
    onRefresh() {
      console.log("点击了刷新小图标");
      this.msg = "";
    },
    onFulfilled() {
      console.log("刷新成功啦！");
    },
    onAgain() {
      console.log("检测到非人为操作的哦！");
      this.msg = "try again";
      // 刷新
      this.$refs.slideblock.reset();
    },
    handleClick() {
      this.$refs.slideblock.reset();
    },
    //end
    submitForm(formName) {
      console.log(this.params)
      this.params.loading = true;
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          console.log(valid);
          this.login(this.params.username, this.params.password);
        } else {
          console.log("参数验证不合法!");
          this.params.loading = false;
          return false;
        }
      });
    },
    login(username, password) {
      login(username, password)
        .then((response) => {
          console.log(response);
          if (response.success == true) {
            //登录成功，将tokenId写入localStorage
            window.localStorage.setItem(TOKEN_KEY, response.data.id);
            this.getUserInfo();
          } else if (response.success == false) {
            this.params.loading = false;
            this.$message({ type: "warning", message: response.message });
          }
        })
        .catch(() => {
          this.params.loading = false;
        });
    },
    getUserInfo() {
      getUserInfo().then((response) => {
        if (response.success) {
          if (response.data.needChangePassword == 1) {
            this.$message({
              type: "warning",
              message: "您的密码需要重新设置！",
            });
            setTimeout(() => {
              window.localStorage.clear();
              this.params.loading = false;
              // window.location.href = window.location.origin + "/login";
            }, 2000);
            return false;
          } else {
            if (new Date().getTime() > response.data.passwordExpireTime) {
              this.$message({
                type: "warning",
                message: "密码过期，请重置密码后登录！",
              });
              setTimeout(() => {
                window.localStorage.clear();
                this.params.loading = false;

                // window.location.href = window.location.origin + "/login";
              }, 2000);
              return false;
            }
          }
          this.$message({ type: "success", message: "登录成功!" });

          this.userInfo = response.data;
          window.localStorage.setItem(REAL_NAME_KEY, response.data.realName);
          window.localStorage.setItem(ROLE_USERID_KEY, response.data.uid);

          window.localStorage.setItem("roleNames", response.data.roleNames);
          window.localStorage.setItem("userId", response.data.uid);
          window.localStorage.setItem("avatar", response.data.avatar);
          this.userInfo.realName = response.data.realName;
          this.userInfo.userId = response.data.userId;
          this.userInfo.roleCode = response.data.roleCode;
          this.userInfo.avatar = response.data.avatar;
          this.$store.commit("setUserInfo", response.data);
          window.localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data)
          );

          this.getPermissionInfo();
        } else {
        }
      });
    },
    getPermissionInfo() {
      getPermissionInfo().then((response) => {
        if (response.success == true) {
          window.localStorage.setItem(
            "menus",
            JSON.stringify(response.data.menus)
          );
          this.$store.commit("setPermissionInfo", response.data);
          let list = [];
          // return false
          response.data.menus.forEach((v) => {
            if (v.children.length == 0 && v.routeAddress) {
              list.push(v.routeAddress);
            } else if (v.children.length > 0) {
              v.children.forEach((c) => {
                list.push(c.routeAddress);
              });
            }
          });
          console.log(window.location.origin);
          if (list.length > 0) {
            this.$router.push(list[0]);
          } else {
            this.params.loading = false;
            this.$router.push("/404");
          }
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.changePassword {
  text-align: center;

  .close {
    margin: 1rem 0;
    width: 95%;
    i {
      align-self: flex-end;
    }
    .title {
      font-size: 16px;
      color: #606266;
    }
  }
}
.slide-verify-slider {
  width: 100% !important;
}
#loginDiv {
  /* margin-top: 14%; */
}
.login {
  position: relative;
  height: 100vh;
  background: url(https://oss.eggxiaoer.com/web/hermes/login-bg.png) no-repeat;
  background-size: 100% 100%;

  .login-mask {
    // display: none;
    height: 100%;
    background: url(https://oss.eggxiaoer.com/web/hermes/login-in.png) no-repeat;
    background-size: 100% 100%;

    /* .respond-to(large, { display: block;}
      ); */
  }

  .login-form {
    display: flex;
    width: 24rem;
    // height: 35em;
    background: #fff;
    border: 10px solid rgba(255, 255, 255, 0.5);
    border-width: 10px;
    border-radius: 4px;
    background-clip: padding-box;
    justify-content: center;
    align-items: center;
    /* .respond-to(xlarge, { margin: 0 56px}); */
  }
  .login-form-wrap {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10em;
    //  .respond-to(large, { width: 40%;});
    // .respond-to(xlarge, { width: 33.3%;});
  }

  .login-form__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #999;
    border-radius: 2px;
    padding: 2rem 0;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin-bottom: 2em;
      img {
        display: inline-block;
        width: 100px;
      }

      .title {
        margin-bottom: 0;
        font-size: 1.5rem;
        // color: @primary-color;
        text-align: center;
        font-weight: bold;
      }
    }
    .rounded-sm {
      // margin-top: 2em;
      transform: translateY(2em);
    }

    form {
      width: 80%;
      .el-form-item {
        margin: 2rem 0;
      }
      .el-form-item:last-child {
        margin: 0rem 0 2rem 0 !important;
      }
      button {
        width: 100%;
      }
    }
  }
}
</style>
